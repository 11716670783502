import {
  Array,
  Boolean,
  Dictionary,
  Literal,
  Null,
  Number,
  Optional,
  Record as RRecord,
  Static,
  String,
  Undefined,
  Union,
  Unknown,
} from "runtypes";

import { getNormalEnum } from "./runtypeEnums";

// !!! IMPORTANT !!! This file to be kept in sync with @hex/python-kernel-startup/python_kernel_startup/formatters.py

// Dynamic values allow for the ability to use the value of a given variable
// defined in the kernel elsewhere (e.g. the frontend).
// Since reading the underlying value requires code execution, we store
// the resolved (or "filled") value in a cell output of type FilledDynamicValue.
// To resolve all filled dynamic values for a cell, one simply needs
// to get all outputs and filter to the filled dynamic values using MIMETYPES
export const DynamicValue = RRecord({
  variableName: String.Or(Null),
});
export type DynamicValue = Static<typeof DynamicValue>;

export const DynamicDataFrameValue = RRecord({
  variableName: String.Or(Null),
  dfName: String.Or(Undefined),
  columnName: Optional(String),
  displayColumnName: Optional(String),
});
export type DynamicDataFrameValue = Static<typeof DynamicDataFrameValue>;

export const DynamicValueTypes = Union(DynamicValue, DynamicDataFrameValue);

// MIME type is FILLED_DYNAMIC_VALUE_MIMETYPE
export const FilledDynamicValue = RRecord({
  variableName: String,
  value: Unknown,
  truncated: Boolean.Or(Undefined),
});
export type FilledDynamicValue = Static<typeof FilledDynamicValue>;

// Most data types are straight forward to serialize for dynamic values,
// however, tables require an agreed upon format, so below is a set of types
// for how we serialize pandas dataframes

export const FilledDynamicValueTableColumnTypeLiteral = Union(
  Literal("STRING"),
  Literal("NUMBER"),
  Literal("DATETIME"),
  Literal("DATETIMETZ"),
  Literal("DATE"),
  Literal("TIME"),
  Literal("UNKNOWN"),
  Literal("BOOLEAN"),
);
export type FilledDynamicValueTableColumnType = Static<
  typeof FilledDynamicValueTableColumnTypeLiteral
>;
export const FilledDynamicValueTableColumnType = getNormalEnum(
  FilledDynamicValueTableColumnTypeLiteral,
);

export const FilledDynamicValueTableHeader = RRecord({
  id: String, // a stable uuid across runs
  columnName: Union(String, Number),
  type: FilledDynamicValueTableColumnTypeLiteral,
});
export type FilledDynamicValueTableHeader = Static<
  typeof FilledDynamicValueTableHeader
>;

export const FilledDynamicValueTableHeaders = Array(
  FilledDynamicValueTableHeader,
);
export type FilledDynamicValueTableHeaders = Static<
  typeof FilledDynamicValueTableHeaders
>;

// header id -> contents
export const FilledDynamicValueTableContents = Dictionary(
  Array(Union(String, Null, Undefined, Number, Boolean)),
);
export type FilledDynamicValueTableContents = Static<
  typeof FilledDynamicValueTableContents
>;

export const FilledDynamicValueTable = RRecord({
  // if `contents_column_names` argument is provided when making
  // the kernel request, we only get back contents data for the
  // column names listed in the argument, the motivation being
  // not fetching unnecessary data and reducing data sent over the wire
  contents: FilledDynamicValueTableContents,
  headers: FilledDynamicValueTableHeaders,
  rowCount: Number,
  totalRowCount: Optional(Number),
  preview: Optional(Boolean),
});
export type FilledDynamicValueTable = Static<typeof FilledDynamicValueTable>;
